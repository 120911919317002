import store from "@/store";
import {PermissionEnum, RoleEnum} from "../gen/types";
import { WritePermissionsByRoute } from "../types/PermissionsByRoute";

const calculatePageCount = function(perPage: number, totalItems: number) {
  if (perPage === 0) {
    throw "Não é possível dividir por 0";
  }
  return Math.ceil(totalItems / perPage);
}

const hasWritePermission = function(route: string): boolean {
  const writePermissions = WritePermissionsByRoute[route];
  if (writePermissions && writePermissions.length > 0 && store.getters.hasPermissions(writePermissions)) {
    return true;
  } else {
    return false;
  }
}

const hasPermission = function(permission: PermissionEnum) {
  return store.getters.hasPermission(permission);
}

const hasRoles = function(roles: RoleEnum[]) {
  return store.getters.hasRoles(roles);
}

const openWhats = function (whatsapp: string, msg?: string) {
  const text = encodeURIComponent(msg? msg : 'Olá')
  if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
      )
  ) {
    window.open(
        `https://api.whatsapp.com/send?phone=${whatsapp}&amp;text=${text}`,
        '_blank',
    )
  } else {
    window.open(
        `https://web.whatsapp.com/send?phone=${whatsapp}&amp;text=${text}`,
        '_blank',
    )
  }
}

export { calculatePageCount, hasWritePermission, hasPermission, hasRoles, openWhats };
